import React from "react";
import "../styles/CustomStyles/Pricing.css";

export const Pricing = (props) => {
  return (
    <section id="pricing" className="pricing">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <br /><br /><br />
          <p>Check our Pricing</p>
        </header>

        <div className="row gy-4" data-aos="fade-left">

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
            <div className="box">
              <h3>Basic</h3>
              <ul>
                <li>Comprehensive Customer Profile.</li>
                <li>Services and Packages Management.</li>
                <li>Employees and Commission Based Salary Management.</li>
                <li>Comprehensive Reports.</li>
              </ul>
              <div className="price"><sup>₱</sup>2,500<span> / mo</span></div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div className="box">
              <h3  >Standard</h3>
              <ul>
                <li>Comprehensive Customer Profile.</li>
                <li>Services and Packages Management.</li>
                <li>Employees and Commission Based Salary Management.</li>
                <li>Inventory and Sales Management.</li>
                <li>Expenses Management.</li>
              </ul>
              <div className="price"><sup>₱</sup>3,500<span> / mo</span></div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div className="box">
              <h3  >Premium</h3>
              <ul>
                <li>Comprehensive Customer Profile.</li>
                <li>Services and Packages Management.</li>
                <li>Employees and Commission Based Salary Management.</li>
                <li>Inventory and Sales Management.</li>
                <li>Expenses Management.</li>
                <li>Gift certificate Management.</li>
                <li>Free Service Management.</li>
                <li>Comprehensive Reports.</li>
              </ul>
              <div className="price"><sup>₱</sup>3,500<span> / mo</span></div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="box">
              <h3  >Customization</h3>
              <ul>
                <li>Issues faced by clients.</li>
                <li>Enable businesses to address specific challenges.</li>
                <li>Ensuring clarity of expectations and requirements.</li>
                <li>Builds trust and strengthens client relationships.</li>
                <li>Providing targeted and effective solutions.</li>
                <li>Adapt to evolving customer needs, market trends, or changes in business requirements.</li>
                <li>Automates tasks, cuts paperwork, and boosts clinic efficiency.</li>
                <li>Facilitates personalized treatment plans and monitors patient progress over time.</li>
              </ul>
              <a href="#temp" className="btn btn-ask">Talk to us</a>
            </div>
          </div>

        </div>

      </div>

    </section>
  );
};
