import React from "react";
import "../styles/CustomStyles/About.css";

export const About = (props) => {
  return (
    <section id="about" className="about">
      <div className="container pt-5" data-aos="fade-up">
        <div className="row gx-0">
          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="first-content text-lg-end text-center">
              <img src={"/images/myt-logo.png"} className="img-fluid" alt=""/>
              <p>
                MYT SoftDev Solutions Inc. is a 100% Filipino-owned private software development company established in 2012. MYT SoftDev Solutions Inc.
              </p>
            </div>
          </div>

          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content text-center text-md-start">
              <h2>We do things differently </h2>
              <h2>We help grow your business</h2>
              <h2>We build smarter stuff</h2>
              <div className="text-center text-lg-end">
                <a href="https://mytsoftdevsolutions.com/about" className="btn btn-learn-more d-inline-flex align-items-center justify-content-center align-self-center" target="_blank" rel="noopener noreferrer">
                  <span>Learn More</span>
                  <i className="bi bi-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
      <br />
      <br />
    </section>
  );
};
