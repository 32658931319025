import React from "react";
import "../styles/CustomStyles/Footer.css";

export const Footer = (props) => {
  return (
    <footer id="footer" className="footer">

      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-12 footer-info text-center text-md-start">
                <span>Get In Touch</span>
              <div className="social-links mt-3">
                <a href="https://www.facebook.com/MYTSoftDevSolutions" className="facebook" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="https://www.linkedin.com/company/myt-software/about/" className="linkedin" target="_blank" rel="noopener noreferrer">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 footer-contact text-center text-md-start">
              <h4><i class="bi bi-geo-alt"></i> Where we are</h4> 
              <p>
                <strong>Email:</strong> info@mytsoftdevsolutions.com<br />
                <strong>Phone:</strong> +63 917 327 6230<br />
              </p>
            </div>

            <div className="col-lg-4 col-md-12 footer-contact text-center text-md-start">
              <h4><i class="bi bi-geo-alt"></i> Where we are</h4> 
              <p>
                301 The Greenery, Pope John Paul II Avenue, Kasambagan, Cebu City, Philippines   <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
