import React from "react";
import "../styles/CustomStyles/Home.css";

export const Home = (props) => {

  return (
    <section id="home" className="home">

      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-6 pt-2 pt-lg-0 order-2 order-lg-1 d-flex align-items-center text-center text-md-start">
            <div data-aos="zoom-out">
              <img
                src={"/dermatech-logo-v2.png"}
                className="img-fluid"
                alt=""
              />
              <h2>A comprehensive platform seamlessly integrates MYT's advanced medical technology solutions.</h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a href="#features" className="btn btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Get Started</span>
                    <i className="bi bi-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 home-img" data-aos="zoom-out" data-aos-delay="300">
          <img
                src={"/images/hero.png"}
                className="img-fluid"
                alt=""
              />
          </div>
        </div>
      </div>

      <svg
        className="home-waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id="wave-path"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          ></path>
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
        </g>
      </svg>


    </section>
  );
};
