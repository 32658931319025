import React from "react";
import "../styles/CustomStyles/Features.css";

export const Features = (props) => {
  return (
    <section id="features" className="features">

      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <p>Why choose DermaTech?</p>
          <h3>At DermaTech, we pride ourselves on offering comprehensive services that cater to the specialized needs of the dermatology industry. Our range of services encompasses every aspect of medical storage, processing, and handling access, ensuring that your valuable medical assets are managed with the utmost care and precision.</h3>
          <br />
        </header>

        <div className="row">

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="box">
              <h3>Automative  process</h3>
              <img src={"/images/features/automative.png"} className="img-fluid" alt=""></img>
              <p>Designed for efficient production, enhanced efficiency, and unmatched accuracy.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div className="box">
              <h3>Chain Inventory</h3>
              <img src={"/images/features/inventorty.png"} className="img-fluid" alt=""></img>
              <p>Streamlining control of skincare products and medical supplies for dermatological practices.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
            <div className="box">
              <h3>Monitor</h3>
              <img src={"/images/features/monitor.png"} className="img-fluid" alt=""></img>
              <p>Improves efficiency in product sales tracking and balance control.</p>
            </div>
          </div>

        </div>

        <br />
        <div className="row feature-icons">

          <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
            <img src={"/images/features/profile-management.png"} className="img-fluid p-4" alt="" />
          </div>

          <div className="col-xl-8 d-flex content">
            <div className="row align-self-center gy-4">

              <div className="col-md-12 icon-box" data-aos="fade-up">
                  <h5 className="text-center">Profile Management</h5>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <h4>• Personal Information</h4>
                  <p>Enhanced personalized service and Contact Details.</p>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                <div>
                  <h4>• Purchase History</h4>
                  <p>Customer engagement, targeted promotions</p>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="300">
                <div>
                  <h4>• Transaction Records</h4>
                  <p>Efficient order tracking, accurate billing, and proactive identification</p>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div className="row feature-icons">

          <div className="col-xl-8 pt-2 pt-lg-0 order-2 order-lg-1 d-flex content">
            <div className="row align-self-center gy-4">

              <div className="col-md-12 icon-box" data-aos="fade-up">
                <div>
                  <h5>Services and Packages Management</h5>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <p>Provides a centralized platform for managing pricing, descriptions, and availability, optimizing the overall service delivery pr ocess and enhancing customer satisfaction.</p>
                </div>
              </div>

            </div>
          </div>

          <div className="col-xl-4 order-1 order-lg-2 text-center" data-aos="fade-left" data-aos-delay="100">
            <img src={"/images/features/service.png"} className="img-fluid p-4" alt="" />
          </div>

        </div>
        <div className="row feature-icons">

          <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
            <img src={"/images/features/inventory.png"} className="img-fluid p-4" alt="" />
          </div>

          <div className="col-xl-8 d-flex content">
            <div className="row align-self-center gy-4">

              <div className="col-md-12 icon-box" data-aos="fade-up">
                <div>
                  <h5>Inventory and Sales Management</h5>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <p>Maintain adequate stock, minimizes errors, and enhances overall operational efficiency, resulting in improved customer service and increased revenue.</p>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div className="row feature-icons">

          <div className="col-xl-8 pt-2 pt-lg-0 order-2 order-lg-1 d-flex content">
            <div className="row align-self-center gy-4">

              <div className="col-md-12 icon-box" data-aos="fade-up">
                <div>
                  <h5>Expenses Management</h5>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <p>Simplifies financial tracking, improves transparency, and empowers organizations to make informed decisions for optimal resource allocation and financial health.</p>
                </div>
              </div>

            </div>
          </div>

          <div className="col-xl-4 order-1 order-lg-2 text-center" data-aos="fade-left" data-aos-delay="100">
            <img src={"/images/features/expenses.png"} className="img-fluid p-4" alt="" />
          </div>

        </div>
        <div className="row feature-icons">

          <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
            <img src={"/images/features/gift.png"} className="img-fluid p-4" alt="" />
          </div>

          <div className="col-xl-8 d-flex content">
            <div className="row align-self-center gy-4">

              <div className="col-md-12 icon-box" data-aos="fade-up">
                <div>
                  <h5>Gift certificate Management</h5>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <p>Enhance customer engagement, attract new clients, and ensure a seamless and organized process for handling gift certificates from issuance to redemption.</p>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div className="row feature-icons">

          <div className="col-xl-8 pt-2 pt-lg-0 order-2 order-lg-1 d-flex content">
            <div className="row align-self-center gy-4">

              <div className="col-md-12 icon-box" data-aos="fade-up">
                <div>
                  <h5>Free Service Management</h5>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <p>Enhance customer satisfaction, monitor the impact of complimentary offerings, and optimize their overall service strategy.</p>
                </div>
              </div>

            </div>
          </div>

          <div className="col-xl-4 order-1 order-lg-2 text-center" data-aos="fade-left" data-aos-delay="100">
            <img src={"/images/features/free.png"} className="img-fluid p-4" alt="" />
          </div>

        </div>
        <div className="row feature-icons">

          <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
            <img src={"/images/features/comprehensive.png"} className="img-fluid p-4" alt="" />
          </div>

          <div className="col-xl-8 d-flex content">
            <div className="row align-self-center gy-4">

              <div className="col-md-12 icon-box" data-aos="fade-up">
                <div>
                  <h5>Comprehensive Reports</h5>
                </div>
              </div>

              <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                <div>
                  <h4>• Daily Transactions Report</h4>
                  <h4>• Customer Balance Report</h4>
                  <h4>• Daily Sales report</h4>
                  <h4>• Daily Product Sales</h4>
                  <h4>• Daily Service Sales</h4>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

    </section>
  );
};
