import React, { useEffect } from "react";

// Import AOS
import 'aos/dist/aos.css';
import AOS from 'aos';

import { Navigation } from "./components/Navigation";
import { Footer } from "./components/Footer";
import { Home } from "./pages/Home";
import { Features } from "./pages/Features";
import { Pricing } from "./pages/Pricing";
import { About } from "./pages/About";

// CSS
import "./styles/CommonStyles/Common.css";
import "./styles/CommonStyles/Responsiveness.css";
import "./App.css";

import FacebookMsg from "./components/FacebookMsg";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, once: true
    });
  }, []);

  return (
    <>
      <Navigation />
      <Home />
      <Features />
      <Pricing />
      <About />
      <Footer />
      <FacebookMsg />
    </>
  );
};

export default App;
