import { FacebookProvider, CustomChat } from 'react-facebook';

const FacebookMsg = () => {
    return(
        <FacebookProvider appId="276884145216298" chatSupport>
            <CustomChat pageId="245671181954844" minimized={true}/>
        </FacebookProvider> 
    );
};

export default FacebookMsg;